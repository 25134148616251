// import React, { useState } from "react";
import { render } from "react-dom";
// Import Highcharts
import Highcharts from "highcharts/highmaps";
// import worldMap from "@highcharts/map-collection/custom/world.geo.json";
// import usalll from "@highcharts/map-collection/custom/usa-and-canada.topo.json";

import usAll from "./usAll";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState,useRef } from "react";
import { format } from 'echarts';
import { withWidth } from '@material-ui/core';



const MIUSMap = ({stateData, height, clicked}) => {
  const [options,setOptions] = useState();
  const [onc,setOnC] = useState();
 
useEffect(() => {
   const getOption = async () => {
     const topology = await fetch(
       "https://code.highcharts.com/mapdata/countries/us/us-all.topo.json"
     ).then((response) => response.json());
     const option = {
       chart: {
         map: topology,
         width: height[1],
         // width:100 +'%'
         // minWidth:30,
         //  width: 30+'%'
       },
       credits: {
         enabled: false,
       },
       mapNavigation: {
         // enabled: true,
         buttonOptions: {
           alignTo: "spacingBox",
         },
       },
       colorAxis: {
         accessibility: {
           enabled: true,
         },
         stops: [
           [0, "#b3cde0"],
           [0.1, "#6497b1"],
           [0.3, "#005b96"],
           [0.5, "#03396c"],
           [1, "#011f4b"],
         ],
         className: "heatmaplegend",
         visible: true,
       },
       legend: {
         enabled: false,
       },
       title: {
         enabled: false,
         text: null,
       },
       tooltip: {
         headerFormat: "",
         // pointFormat: `<div style="font-size:11px">{point.name}: $<b>{point.main}M</b><br /><span style="color:blue">●</span><p> Cherre : <b>{point.for} M</b></p><br /><span style="color:Orange">●</span><p> Nedl : <b>{point.nedl} M</b></p></div>`
         pointFormat: `<div style="font-size:11px">{point.name}: <b>{point.main}M</b></div>`,
         style: {
           color: "#303030",
           fontWeight: "400",
           fontFamily: "Calibri",
           fontSize: "11px",
         },
       },
       plotOptions: {
         series: {
           events: {
             click: function (e) {
               clicked(e.point.n);
               // var text = '<b>Clicked</b><br>Series: ' + this.name +
               //         '<br>Point: ' + e.point.name + ' (' + e.point.value + '/km²)';

               console.log("Map Clicked State", e.point.n);
               // if (!this.chart.clickLabel) {

               //     this.chart.clickLabel = this.chart.renderer.label(text, 0, 250)
               //         .css({
               //             width: '280px'
               //         })
               //         .add();
               // } else {
               //     this.chart.clickLabel.attr({
               //         text: text
               //     });
               // }
             },
           },
         },
       },
       series: [
         {
           name: "States",
           states: {
             hover: {
               color: "#BADA55",
             },
           },
           dataLabels: {
             enabled: true,
             format: "{point.n}",
           },
           // formatter: `<div style="position:absolute">{point.for:,.2f}</div>`,
           // allAreas: true,
           data: stateData,
         },
       ],
     };
     setOptions(option);
   };
 getOption();
}, [stateData,clicked,height])


 

   return (
    <div className="map-container">
    {options && (
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"mapChart"}
        options={options}
      />
    )}
  </div>
   )
  
}

export default MIUSMap;
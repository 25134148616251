import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";
import Loader from "../../components/AAANEWCOMPONENT/Loader/Loader";
import "./StripeForm.css";
import StripeCheckout from "./StripePayment";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import { Cancel, Close, CloseFullscreen } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Button,
  Chip,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
  Snackbar,
  IconButton,
  CardActions,
  CardContent,
  CardMedia,
  Card,
} from "@mui/material";
import ToasterLayout from "../../Layout/ToastProvider";

const AddSubUserForm = ({
  subscriptionFor,
  amt,
  plan,
  product,
  productCoupon,
}) => {
  const [open, setOpen] = useState(false);
  let STRIPE_KEY = process.env.REACT_APP_STRIPE_TEST_KEY;
  const stripePromise = loadStripe(`${STRIPE_KEY}`);
  const appearance = {
    theme: "stripe",
    variables: {
      fontSize: "11px",
      padding: "5px",
      fontFamily: "Arial", // Font family for text
      fontWeight: "400", // Normal font weight
    },
  };
  // Enable the skeleton loader UI for optimal loading.
  const loader = "auto";

  const [selectedSubscriptionType, setSelectedSubscriptionType] =
    useState(subscriptionFor);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailspaceerror, setemailSpaceError] = useState(false);
  const [emailerror, setemailError] = useState(false);
  const [checked, setChecked] = useState([]);
  const [checkedRegion, setCheckedRegion] = useState([]);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [msaRegions, setMSARegions] = useState([]);
  const [msas, setMsas] = useState([]);
  const [selectedMsas, setSelectedMsas] = useState([]);
  let planNumber = plan === "yearly" ? "3" : "1";
  const [selectPlan, setSelectPlan] = useState(planNumber);
  const [filter, setFilter] = useState("");
  const [priceToDisplay, setPriceToDisplay] = useState(amt);
  const [finalAmt, setFinalAmt] = useState(null);
  const [finalSubscriptionFor, setFinalSubscriptionFor] = useState(null);
  const [finalPlan, setFinalPlan] = useState(null);
  const [finalSelectedData, setFinalSelectedData] = useState(null);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [products, setProducts] = useState(product);
  const [showPageNotFound, setShowPageNotFound] = useState(false);
  const [selectedData, setSelectedDatas] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [validUser, setValidUser] = useState("");

  useEffect(() => {
    getSubscriptionTypes();
    getMSARegions();
  }, []);
  const subFor =
    selectedSubscriptionType !== null
      ? selectedSubscriptionType
      : subscriptionFor;
  const fd = selectedData.length > 0 ? selectedData : null;

  const planName = selectPlan === "1" ? "Monthly" : "Annually";
  const filteredProducts = productCoupon.data.filter(
    (product) =>
      product.name.includes(
        subFor.name === "All" ? "Nationwide" : subFor.name
      ) && product.name.includes(planName)
  );
  const handleProceedSubscription = async () => {
    setValidUser(false);
    const userValid = await axios
      .post(`${process.env.REACT_APP_CHECK_EMAIL}`, {
        email: email,
      })
      .catch((error) => {
        toast.error(error?.message);
      });

    if (userValid.data) {
      toast.error("User Is Already Present");
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_SEARCH}`,
          {
            email: email,
            firstname: firstName,
            lastname: lastName,
            subscription: subFor.name,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.REACT_APP_HUBSPOT_TOKEN}`,
            },
          }
        )
        .then((response) => {
          if (response) {
            toast.success("User Success! Moving To 💵");
          }

          // toast.custom(<CustomToaster id={22} message={"This is a custom message"}/>)
        })
        .catch((error) => {
          // showToast(error, "error");
          toast.error(error);
        });
      setProducts(filteredProducts);
      setFinalSelectedData(fd);
      setFinalSubscriptionFor(subFor);
      setFinalAmt(priceToDisplay);
      setFinalPlan(selectPlan);
      setShowPaymentForm(true);
    }
  };

  const getSubscriptionTypes = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_SUBSCRIPTION_TYPES}`
        // {
        //          headers: {
        //            Authorization: `Bearer ${userToken}`,
        //          },
        //        }
      )
      .then((data) => {
        let dd = data.data;
        setSubscriptionTypes(dd);
        if (subscriptionFor !== undefined) {
          const selectedSubType = dd.find(
            (u) => u.name.toLowerCase() === subscriptionFor.toLowerCase()
          );
          setSelectedSubscriptionType(selectedSubType);
        }
      });
  };
  const getMSARegions = async () => {
    await axios.get(`${process.env.REACT_APP_MSA_REGIONS}`).then((data) => {
      let dd = data.data;
      setMSARegions(dd);
    });
  };

  const handleSubscriptionType = (value) => {
    setSelectedDatas([]);
    setSelectedSubscriptionType(value);
  };

  const handleEmail = (e) => {
    const { value } = e.target;
    const hasSpaces = /\s/.test(value);
    const emailValidator =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const invalidEmail = !emailValidator.test(value);
    setemailError(invalidEmail);
    setEmail(value.toLowerCase());
    setemailSpaceError(hasSpaces);
  };
  const landingPageTemplate = (option) => {
    return (
      <div className="landing_page-item">
        <div>{option.name}</div>
      </div>
    );
  };
  // const handleNavigateBack = () => {
  //   if (clicked === undefined) {
  //     window.location.href = "https://nedl.us/multifamily-market/";
  //   } else {
  //     navigate("/subscription");
  //   }
  // };

  // Filter list based on search input
  const uniqueRegions = Array.from(
    new Set(msaRegions.map((msa) => msa.region))
  ).map((region) => ({ region })); // Return objects with only region
  // Get corresponding MSA object for each unique region

  const filteredMSAs =
    subFor.name === "MSA"
      ? msaRegions.filter((msa) =>
          msa.msa_name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : uniqueRegions.filter((msa) =>
          msa.region.toLowerCase().includes(searchTerm.toLowerCase())
        );

  // Add to selected items
  const handleSelect = (msa) => {
    // Check if the type is "MSA" or "Region"
    const valueToAdd = subFor.name === "MSA" ? msa.msa_name : msa.region;

    // Avoid adding duplicates
    if (!selectedData.includes(valueToAdd)) {
      setSelectedDatas([...selectedData, valueToAdd]);
    }
  };

  // Remove Chip when clicked
  const handleRemove = (value) => {
    setSelectedDatas(selectedData.filter((name) => name !== value));
  };
  const handleClose = (event) => {
    event.prevent.Default();

    setOpen(false);
  };
  const isValid =
    email.trim() !== "" &&
    firstName.trim() !== "" &&
    lastName.trim() !== "" &&
    selectPlan.trim() !== "" &&
    selectedData.length > 0;
  if (showPaymentForm) {
    return (
      <Elements options={{ appearance, loader }} stripe={stripePromise}>
        <StripeCheckout
          email={email}
          firstName={firstName}
          lastName={lastName}
          product={products}
          quantity={selectedData.length}
          subsData={selectedData}
          subFor={subFor}
          msaRegion={msaRegions}
          plan={selectPlan}
        />
      </Elements>
    );
  }

  return (
    <div className="subscribeNowPage">
      <ToasterLayout />

      <h4 style={{ textAlign: "left", padding: "30px 60px" }}>
        {/* <i
                  onClick={handleNavigateBack}
                  className="pi pi-chevron-circle-left backIcon"
                ></i> */}
        Subscription Details
      </h4>

      {/* <div className="fillSubMainContainer"> */}
      <div className="panelContentPaymentAddSub">
        {msaRegions.length > 0 ? (
          <div className="spform">
            <div className="addorsubscribepaymentform ">
              <div className="spleft">
                <div className="dropdownPropertyBasicPayment">
                  <div className="dropdownContentPayment">
                    <label className="labelstyle">
                      First Name
                      <span
                        style={{
                          color: "#FF0000",
                          fontSize: "16px",
                          fontWeight: "bolder",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <InputText
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="Enter First Name"
                      keyfilter="alpha"
                      required
                    />
                  </div>
                  <div className="dropdownContentPayment">
                    <label className="labelstyle">
                      Last Name
                      <span
                        style={{
                          color: "#FF0000",
                          fontSize: "16px",
                          fontWeight: "bolder",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <InputText
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Enter Last Name"
                      keyfilter="alpha"
                      required
                    />
                  </div>
                  <div className="dropdownContentPayment">
                    <label className="labelstyle">
                      Email ID
                      <span
                        style={{
                          color: "#FF0000",
                          fontSize: "16px",
                          fontWeight: "bolder",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <InputText
                      value={email}
                      onChange={handleEmail}
                      placeholder="Enter Email ID"
                      required
                    />
                    {(email !== "" && emailerror) ||
                    (email !== "" && emailspaceerror) ? (
                      <label className="paymentemailerror">Invalid Email</label>
                    ) : null}
                  </div>
                </div>
                <div className="dropdownPropertyBasicPayment">
                  <div
                    className="dropdownContentPayment"
                    style={{
                      width: "60%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                    }}
                  >
                    <label className="labelstyle">
                      Subscription Type
                      <span
                        style={{
                          color: "#FF0000",
                          fontSize: "16px",
                          fontWeight: "bolder",
                        }}
                      >
                        *
                      </span>
                    </label>
                    <Dropdown
                      value={selectedSubscriptionType}
                      options={subscriptionTypes}
                      key="st"
                      onChange={(e) => handleSubscriptionType(e.value)}
                      optionLabel="name"
                      placeholder="Select Subcription Type"
                      itemTemplate={landingPageTemplate}
                    />
                  </div>
                </div>

                <div className="dropdownPropertyBasicPayment">
                  <label className="labelstyle">
                    Select Your Plan
                    <span
                      style={{
                        color: "#FF0000",
                        fontSize: "16px",
                        fontWeight: "bolder",
                      }}
                    >
                      *
                    </span>
                  </label>
                  <div className="dropdownContentPaymentPlan">
                    <div className="p-field-radiobutton">
                      <RadioButton
                        inputId="monthly"
                        name="monthly"
                        value="1"
                        onChange={(e) => setSelectPlan(e.value)}
                        checked={selectPlan === "1"}
                      />
                      <label htmlFor="monthly">Monthly</label>
                    </div>
                    <div className="p-field-radiobutton">
                      <RadioButton
                        inputId="annually"
                        name="annually"
                        value="3"
                        onChange={(e) => setSelectPlan(e.value)}
                        checked={selectPlan === "3"}
                      />
                      <label htmlFor="annually">Annually</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="spright">
                {subFor.name === "All" ? (
                  <img
                    className="us-national-map"
                    src="/images/payment/National Map.jpg"
                    alt="United States"
                  />
                ) : (
                  <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
                    {/* Selected Items on the Left */}
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column", // Chips appear one by one
                        gap: 1,
                        alignItems: "start",
                        p: 1,
                        overflowY: "auto",

                        minHeight: 150,
                        maxHeight: 200, // Scroll if many selected
                        // Limit height
                      }}
                    >
                      <h3>My Selection</h3>
                      {selectedData.length === 0 ? (
                        <span style={{ color: "#888" }}>No selection</span>
                      ) : (
                        selectedData.map((value, index) => {
                          return (
                            <Chip
                              key={index}
                              label={value}
                              onDelete={() => handleRemove(value)} // Cross icon functionality
                              deleteIcon={<Cancel />} // Cross icon
                              sx={{ cursor: "pointer" }}
                            />
                          );
                        })
                      )}
                    </Box>

                    {/* Searchable List on the Right */}
                    <Paper sx={{ width: 300, p: 2 }}>
                      <TextField
                        fullWidth
                        label={
                          subFor.name === "MSA" ? "Search MSA" : "Search Region"
                        }
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <List
                        sx={{
                          maxHeight: 200,
                          overflowY: "auto",
                          minHeight: 150,
                        }}
                      >
                        {filteredMSAs.map((msa) => (
                          <ListItem
                            key={msa.id}
                            onClick={() => handleSelect(msa)}
                            sx={{
                              padding: "none",
                              margin: "0px",
                              "&:hover": {
                                backgroundColor: "#f0f0f0", // Light gray background on hover
                              },
                            }}
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                sx: {
                                  fontSize: "0.9rem",
                                  lineHeight: "0.9rem",
                                }, // Apply font size here
                              }}
                              primary={
                                subFor.name === "MSA"
                                  ? msa.msa_name
                                  : msa.region
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </Box>
                )}
              </div>
            </div>
            <Stack
              direction="row"
              spacing={2}
              justifyContent={"flex-end"}
              sx={{ ml: "auto", mr: "20px" }}
            >
              {isValid && subFor.name !== "All" && (
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={handleProceedSubscription}
                  sx={{
                    backgroundColor: "#324158",
                    "&:hover": { backgroundColor: "#324158" },
                  }}
                >
                  Proceed
                </Button>
              )}
              {subFor.name === "All" &&
                email.trim() !== "" &&
                firstName.trim() !== "" &&
                lastName.trim() !== "" &&
                selectPlan.trim() !== "" && (
                  <Button
                    variant="contained"
                    endIcon={<SendIcon />}
                    onClick={handleProceedSubscription}
                    sx={{
                      backgroundColor: "#324158",
                      "&:hover": { backgroundColor: "#324158" },
                    }}
                  >
                    Proceed
                  </Button>
                )}
            </Stack>
          </div>
        ) : (
          <div className="paymentLoaderDiv">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddSubUserForm;

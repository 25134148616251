import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import "./StripePayment.css";
import CheckIcon from "@mui/icons-material/Check";
import generator from "generate-password";
import moment from "moment";
import {
  Grid,
  TextField,
  CardHeader,
  Avatar,
  Card,
  Box,
  Chip,
  List,
  ListItem,
  ListItemText,
  Paper,
  Button,
  CardContent,
  Typography,
  Stack,
  Divider,
  Checkbox,
  InputAdornment,
  CardMedia,
  CardActionArea,
} from "@mui/material";
import AlertDialogSlide from "./DialogSubscription";
import MultiActionAreaCard from "./DialogSubscription";
import ToasterLayout from "../../Layout/ToastProvider";
import toast from "react-hot-toast";

const CreateSubscription = ({
  email,
  firstName,
  lastName,
  product,
  quantity,
  subsData,
  subFor,
  msaRegion,
  plan,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [formData, setFormData] = useState({
    email: email,
    name: `${firstName} ${lastName}`,
    items: [],
    trialPeriod: 0,
    couponCode: "",
  });
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const options = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [perror, setPError] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [isCoupon, setIsCoupon] = useState(null);

  const handleCoupon = (coupon) => {
    if (!coupon) return; // Prevent empty coupon check
    setIsCoupon(false);
    // Check if coupon exists in the first product's coupons array
    const isCouponValid = product[0]?.coupons.some(
      (c) => c.name.toLowerCase() === coupon.toLowerCase()
    );

    if (!isCouponValid) {
      setIsCoupon(true);
    } else {
      const validCoupon = product[0]?.coupons.find(
        (c) => c.name.toLowerCase() === coupon.toLowerCase()
      );
      setCouponCode(validCoupon.couponId);
    }
  };
const handleSubmit = async (e) => {
  e.preventDefault();

  if (!stripe || !elements) {
    setPError("Stripe is not loaded yet.");
    return;
  }

  setLoading(true);
  setPError("");
  setSuccess("");

  const cardElement = elements.getElement(CardElement);
  if (!cardElement) {
    toast.error("Please enter a valid CARD | CVV | EXP | ZIP ");
    setLoading(false);
    return;
  }

  try {
    // Create PaymentMethod
    const { paymentMethod, error: pmError } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: formData.name,
        email: formData.email,
      },
    });

    if (pmError || !paymentMethod?.id) {
      toast.error(pmError?.message || "Failed to create payment method.");
      setLoading(false);
      return;
    }

    const pmId = paymentMethod.id;
    const payload = {
      email: formData.email,
      name: formData.name,
      items: [{ priceId: product[0].price.priceId, quantity: quantity || 1 }],
      trialPeriod: isChecked ? 14 : 0,
      couponCode: couponCode || "",
      paymentMethodId: pmId,
    };

    // Create Subscription
    const subscriptionResponse = await axios.post(
      `${process.env.REACT_APP_STRIPE_SUBSCRIPTION}`,
      payload
    );

    if (subscriptionResponse.status !== 200) {
      toast.error("Failed to create subscription.");
      setLoading(false);
      return;
    }

    const user = {
      email: email,
      password: generator.generate({ length: 10, numbers: true }),
      firstName: firstName,
      lastName: lastName,
      org: 73,
      role: 2,
      paymentintentid: pmId,
      payment: {
        ...payload,
        subscription: subscriptionResponse.data.subscriptionId,
      },
    };

    // Create User
    const userApiResponse = await axios.post(
      `${process.env.REACT_APP_USER_SIGNUP}`,
      user
    );

    if (userApiResponse.status !== 200) {
      toast.error("Failed to create user.");
      setLoading(false);
      return;
    }

    // Prepare Subscription Data
    let forSubData = {};
    if (subFor.id === 1) {
      forSubData = {
        MSA: msaRegion
          .filter((item) => subsData.includes(item.msa_name))
          .map((item) => item.msa_code),
      };
    } else if (subFor.id === 2) {
      forSubData = {
        MSA: msaRegion
          .filter((item) => subsData.includes(item.region))
          .map((item) => item.msa_code),
        region: subsData,
      };
    } else if (subFor.id === 3) {
      forSubData = { ALL: "ALL" };
    }

    const endd = isChecked ? moment().add(14, "days") : moment();
    const subdataItem = {
      org: 73,
      typeId: subFor.id,
      sub_data: forSubData,
      subscriptionName: email,
      userLimit: 1,
      users: {
        users: [userApiResponse.data.id],
      },
      endDate: endd,
      subFor: plan,
    };

    // Create Subscription Data
    const subApiResponse = await axios.post(
      `${process.env.REACT_APP_SUBSCRIPTION_DATA}`,
      subdataItem
    );

    if (subApiResponse.status === 200) {
      toast.success("You are Subscribed");
      setSuccess(true);
    } else {
      toast.error("Failed to complete subscription data.");
      setSuccess(false);
    }
  } catch (error) {
    console.error("Error:", error);
   
    toast.error(error.message);
  } finally {
    setLoading(false);
  }
};

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!stripe || !elements) {
  //     setPError("Stripe is not loaded yet.");
  //     return;
  //   }

  //   setLoading(true);
  //   setPError("");
  //   setSuccess("");

  //   let response;
  //   try {
  //     const cardElement = elements.getElement(CardElement);
  //     if (!cardElement) {
  //       toast.error("Please enter a valid CARD |CVV|EXP|ZIP ");
  //       setLoading(false);
  //       return;
  //     }
  //     // Create a PaymentMethod
  //     try {
  //       const paymentMethod = await stripe.createPaymentMethod({
  //         type: "card",
  //         card: cardElement,
  //         billing_details: {
  //           name: formData.name,
  //           email: formData.email,
  //         },
  //       });
  //       console.log("PaymentMethod", paymentMethod);
  //       console.log("payment method check ", paymentMethod.paymentMethod.id);

  //       const pmId = paymentMethod?.paymentMethod.id;
  //       const payload = {
  //         email: formData.email,
  //         name: formData.name,
  //         items: [
  //           { priceId: product[0].price.priceId, quantity: quantity || 1 },
  //         ],
  //         trialPeriod: isChecked ? 14 : 0,
  //         couponCode: couponCode || "",
  //         paymentMethodId: pmId,
  //       };

  //       console.log("payloaddd", payload);
  //       try {
  //         response = await axios.post(
  //           `${process.env.REACT_APP_STRIPE_SUBSCRIPTION}`,
  //           payload
  //         );
  //       } catch (error) {
  //         toast.error(error.message);
  //         return;
  //       }
  //       const user = {
  //         email: email,
  //         password: generator.generate({
  //           length: 10,
  //           numbers: true,
  //         }),
  //         firstName: firstName,
  //         lastName: lastName,
  //         org: 73,
  //         role: 2,
  //         paymentintentid: pmId,
  //         payment: {
  //           ...payload,
  //           subscription: response?.data.subscriptionId,
  //         },
  //       };
  //       let userApi;
  //       try {
  //         userApi = await axios.post(
  //           `${process.env.REACT_APP_USER_SIGNUP}`,
  //           user
  //         );
  //       } catch (error) {
  //         toast.error(error.message);
  //         return;
  //       }

  //       let forSubData = {};
  //       if (subFor.id === 1) {
  //         const matchingIds = msaRegion
  //           .filter((item) => subsData.includes(item.msa_name)) // Filter matching msa_name
  //           .map((item) => item.msa_code);

  //         forSubData = { MSA: matchingIds };
  //       }
  //       if (subFor.id === 2) {
  //         const matchingIds = msaRegion
  //           .filter((item) => subsData.includes(item.region)) // Filter matching msa_name
  //           .map((item) => item.msa_code);

  //         forSubData = { MSA: matchingIds, region: subsData };
  //       }
  //       if (subFor.id === 3) {
  //         forSubData = { All: "All" };
  //       }
  //       const endd = isChecked ? moment().add(14, "days") : moment();
  //       const subdataItem = {
  //         org: 73,
  //         typeId: subFor.id * 1,
  //         sub_data: forSubData,
  //         subscriptionName: email,
  //         userLimit: 1,
  //         users: {
  //           users: [userApi.data.id],
  //         },
  //         endDate: endd,
  //         subFor: plan,
  //       };

  //       let subApi;
  //       try {
  //         subApi = await axios.post(
  //           `${process.env.REACT_APP_SUBSCRIPTION_DATA}`,
  //           subdataItem
  //         );
  //       } catch (error) {
  //         toast.error(error.message);
  //         return;
  //       }
  //       if (
  //         response.status === 200 &&
  //         userApi.status === 200 &&
  //         subApi.status === 200
  //       ) {
  //         toast.success("You are Subscribed");
  //         setSuccess(true);
  //       } else {
  //         setSuccess(false);
  //       }
  //     } catch (error) {
  //       toast.error(error.message);
  //       setLoading(false);
  //       return;
  //     }

  //     // Prepare API payload

  //     // Call backend API

  //     // if (subscriptionError) {
  //     //   toast.error(subscriptionError.message);
  //     //   return;
  //     // }

  //     // console.table([response, userApi, subApi]);
  //   } catch (err) {
  //     setPError(
  //       err.response?.data?.error.message || "Unexpected error occurred"
  //     );
  //     toast.error(err.response?.data?.error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const currentDate = new Date().toLocaleString();
  if (success) {
    return (
      <Card
        p={2}
        sx={{
          width: "80%",
          margin: "auto",
        }}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image="/images/mainImage.jpg"
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Successful Creation of Subscription
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              <h2 className="completepage-header" id="status-text">
                Thanks! Your payment was successful
              </h2>

              <h5 className="completepage-footer">
                A confirmation mail was sent to <br />
                {email} <br />
              </h5>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  return (
    <Grid container spacing={2} p={2}>
      <ToasterLayout />
      <Grid item xs={12} md={5}>
        <Paper sx={{ p: 2 }}>
          <Card>
            <CardHeader
              sx={{
                bgcolor: "#44546a",
                color: "#fff",
              }}
              avatar={
                <Avatar
                  sx={{ bgcolor: "#324158" }}
                  aria-label="Nedl"
                  image="\images\nedl_rgb.png"
                >
                  Nedl
                </Avatar>
              }
              title={"Summary"}
              subheader={currentDate}
            />
            <CardContent>
              <Typography component="h4" sx={{ color: "#324158" }}>
                Subscription Details
              </Typography>
              <Divider
                component="div"
                sx={{
                  textDecorationL: "none",
                }}
              />
              <Typography variant="subtitle1" component="div">
                Product : {product[0].name}
              </Typography>
              <Typography variant="subtitle1" component="div">
                Total Amount : USD $
                {(product[0].price.amount / 100) * (quantity || 1)}
              </Typography>
              <Typography variant="subtitle1" component="div">
                {subsData.map((d) => (
                  <li key={d}>{d}</li>
                ))}
              </Typography>
            </CardContent>
          </Card>
        </Paper>
      </Grid>

      <Grid item xs={12} md={7}>
        <Paper sx={{ p: 2 }}>
          <Box
            sx={{
              height: "100%", // Example background color
              padding: 2,
              display: "flex", // added display flex
              flexDirection: "column",
              gap: 2, // ensures elements are stacked
            }}
          >
            <TextField
              id="Name"
              label="Name"
              value={firstName + " " + lastName}
              disabled
            />
            <TextField id="Email" label="Email" value={email} disabled />
            <Divider
              component="div"
              sx={{
                textDecorationL: "none",
              }}
            />
            <Stack
              p={2}
              sx={{
                width: "100%",
                border: "1px solid #eee",
              }}
            >
              <CardElement
                options={options}
                style={{
                  marginBottom: "20px",
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "5px",
                }}
              />
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
              }}
            >
              <TextField
                label="Coupon"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CheckIcon
                        onClick={() => handleCoupon(coupon)}
                        style={{
                          cursor: "pointer",
                          color: isCoupon ? "red" : "#eee",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />

              <Box display="flex" alignItems="center">
                <Typography variant="subtitle1" sx={{
                  fontSize:'.8rem'
                }}>14 Days Trial</Typography>
                <Checkbox checked={isChecked} onChange={handleChange} />
              </Box>
            </Stack>
            {isCoupon === true && (
              <Typography variant="subtitle1" component="div" color="red">
                Coupon is Invalid
              </Typography>
            )}
            {isCoupon === false && (
              <Typography variant="subtitle1" component="div" color="green">
                Coupon is Valid
              </Typography>
            )}
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#324158",
                "&:hover": { backgroundColor: "#324158" },
              }}
              type="button"
              className=""
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? "Processing..." : "Create Subscription"}
            </Button>
          </Box>
        </Paper>
      </Grid>
      {perror && (
        <>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              color: "red",
              display: "flex",
              justifyContent: "right",
              fontSize: "1rem",
              width: "80%",
              margin: "auto",
            }}
          >
            {perror}
          </Typography>
        </>
      )}
    </Grid>
  );
};

export default CreateSubscription;


import React, { useEffect,useState } from 'react';
import { Chart } from 'primereact/chart';
import OverviewAnalysis from '../../components/overviewAnalysis/mioverview'
import MIRevenue from '../HighCharts/Area/mirevenuechar';
import MIContracts from '../HighCharts/Area/micontracts';
import MIDeals from '../HighCharts/Area/mideals';
import MIExpired from '../HighCharts/Area/miexpired';
import MIRent from '../HighCharts/Area/mirent';
import MIOccu from '../HighCharts/Area/mioccu';
import MIRentt from '../HighCharts/Area/mirent';
export const numberFormat = value =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  }).format(value);

  const  numberWithCommas = (x)=> {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const MI4Cards = ({dateLabel,expiredContract,dealClosed,underContract, labels,revenuedata, height,tsize,trate,rent,occu}) => {
 
  let  newTSize = tsize.map(element => {
    if(element === null){
      return 0;
    }else{
      return element/1000000;
    }
  });
  let totalTSize =  newTSize.reduce((c, d) => c + d)
  let  newTRate = trate.map(element => {
    if(element === null){
      return 0;
    }else{
      return element;
    }
  });
  let totalTRate =  newTRate.reduce((c, d) => c + d)

  let  newRent = rent.map(element => {
    if(element === null){
      return 0;
    }else{
      return element;
    }
  });
  let totalRent =  newRent.reduce((c, d) => c + d)

  let  newOccu = occu.map(element => {
    if(element === null){
      return 0;
    }else{
      return element;
    }
  });
  let totalOccu =  newOccu.reduce((c, d) => c + d)

  let  newRevenueData = revenuedata.map(element => {
    if(element === null){
      return 0;
    }else{
      return element/1000000000;
    }
  });
  let totalRevenue =  newRevenueData.reduce((c, d) => c + d)
  // let newExpiredContractsData = expiredContract.map(element => {
  //   if(element === null){
  //     return 0;
  //   }else{
  //     return element;
  //   }
  // });
  let  newDealsClosedData = dealClosed.map(element => {
    if(element === null){
      return 0;
    }else{
      return element;
    }
  });
  // let newUnderContractData = underContract.map(element => {
  //   if(element === null){
  //     return 0;
  //   }else{
  //     return element;
  //   }
  // });
  // let totalExpiredContracts =  newExpiredContractsData?.reduce((c, d) => c + d,0)
  // let totalUnderContract = newUnderContractData?.reduce((c, d) => c + d,0)
  let totalDealClosed =  newDealsClosedData?.reduce((c, d) => c + d,0)


   return (
        <>
        <OverviewAnalysis className="overview"
                img="\images\lattest\save-money.svg"
                alt="Prop"
                header="Sales"
                subHeader={`${numberFormat(totalRevenue)} B`}
                chart={
                 
                <MIRevenue labels={dateLabel} 
                revenuedata = {newRevenueData} 
                min={Math.min(...newRevenueData)}
                max={Math.max(...newRevenueData)}
                  height = {height}
                />
                }
              />
               <OverviewAnalysis className="overview"
                img="\images\deals closed.svg"
                alt="Prop"
                header="Transactions"
                subHeader={`${numberWithCommas(totalDealClosed)}`}
                chart={<MIDeals labels={dateLabel} monthlyDeals={newDealsClosedData}  min={Math.min(...newDealsClosedData)}
                max={Math.max(...newDealsClosedData)}  height = {height}/>}
              />  
              <OverviewAnalysis className="overview"
               img="\images\lattest\mi six card.svg"
                alt="Dollar"
                header="Avg Transaction Size"
                tooltip="Status under agreement and offer accepted"
                subHeader={`$${numberWithCommas((totalTSize/5).toFixed(2))} M`}
              
                chart={<MIContracts  categories = {dateLabel} datac ={newTSize} min={Math.min(...newTSize)}
                max={Math.max(...newTSize)}   height = {height}/>}
                />
                 
              <OverviewAnalysis className="overview"
                img="\images\lattest\money-transfer.svg"
                alt="Dollar"
                header="Avg Transaction Frequency"
                
                subHeader={`${(totalTRate/5).toFixed(2)}`}
                chart={<MIExpired  categories = {dateLabel} data ={newTRate} min={Math.min(...newTRate)}
                max={Math.max(...newTRate)}   height = {height}/>}

              />
               <OverviewAnalysis className="overview"
               img="\images\lattest\business-and-trade.svg"
               alt="Dollar"
                header="Avg Rental Rate"
             
                subHeader={`$${numberWithCommas((totalRent/5).toFixed(0))}`}
                chart={<MIRent  categories = {dateLabel} data ={newRent} min={Math.min(...newRent)}
                max={Math.max(...newRent)}   height = {height}/>}
                />
                 
              <OverviewAnalysis className="overview"
               img="\images\lattest\insurance-company.svg"
               alt="Dollar"
                header="Avg Occupancy Rate"
                tooltip="Status not changed for past 90 days"
                subHeader={`${(totalOccu/5).toFixed(2)}%`}

                chart={<MIOccu  categories = {dateLabel} data ={newOccu}  min={Math.min(...newOccu)}
                max={Math.max(...newOccu)}  height = {height}/>}

              />
              
             
        </>
    )
}

export default MI4Cards;
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./marketIntelligence.css";
import MIUSMap from "../../components/MIUSMap/MIUSMap";
import MI4Cards from "../../components/MI4Cards/MI4Cards";
import moment from "moment";
import ownerstate from "../../Data/ownerState.json";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { now } from "moment";
// import RatingComponent from "./rating";
// import Chat from "./chat";
// import { Dropdown } from "primereact/dropdown";
// import { Button } from 'primereact/button';
// import { ListBox } from 'primereact/listbox';
// import { Divider } from 'primereact/divider';
import { Sidebar } from "primereact/sidebar";
import MISidebar from "../../components/AAANEWCOMPONENT/SidebarOverlay/MISidebar";
import { Skeleton } from "primereact/skeleton";
import NormalLoader from "../../components/AAANEWCOMPONENT/Loader/NormalLoader";
import FeedsLoader from "../../components/AAANEWCOMPONENT/Loader/FeedsLoader";
import Newsfeed from "../../components/NewsFeed/newsfeed";
// import MIBarChart from "../../components/MIBarChart/MIBarChart";
import MIBar from "../../components/HighCharts/MIBar/MIBar";
// import newsfeedsDataTest from "../../Data/newsFeedsTest.json"
import useAddAction from "../../components/AAANEWCOMPONENT/ActionAndSession/addAction";
import { Paper, Stack, Typography } from "@mui/material";

export const numberFormat = (value) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
const MarketIntelligence = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  let previousMonth = currentMonth - 6;
  let previousYear = currentYear;
  if (previousMonth < 0) {
    previousMonth = 12 + previousMonth;
    previousYear -= 1;
  }

  const previousMonthDate = new Date(previousYear, previousMonth, 1);
  const [barchartdate, setBarChartDate] = useState(previousMonthDate);
  const userData = JSON.parse(sessionStorage.getItem("userdata"));
  const [orgDetails, setOrgDetails] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const userDetails = JSON.parse(sessionStorage.getItem("session_data"));
  const userToken = userDetails.data.token;
  const islogged = sessionStorage.getItem("logged");
  const fname = userData.data.name;
  const role = userData.data.role;
  const organization = userData.data.organization;
  // const orgData = JSON.parse(sessionStorage.getItem("orgdata"));
  // const superadminorg = orgData?.agent_id;
  const org =
    role === "Super Admin" && selectedOrg !== null
      ? selectedOrg?.agent_id
      : organization;
  const [lorg, setLOrg] = useState(org);
  const [liveData, setLiveData] = useState(null);
  const [newsData, setNewsData] = useState(0);
  const [marketinsights, setMarketInsights] = useState(0);
  const [dealclosed, setDealClosed] = useState([]);
  const [group, setGroup] = useState(0);
  const [allKey, setAllKey] = useState();
  const [keyv, setKeyv] = useState(0);
  const [allKeyapi, setAllKeyapi] = useState(0);
  const [keyvapi, setKeyvapi] = useState(0);
  const [loader, setloader] = useState(0);
  const [oldgroupState, setOldGroupState] = useState(0);
  const [height, setHeight] = useState(0);
  const [sumleads, setSumLeads] = useState([]);
  const [sumDeals, setSumDeals] = useState([]);
  const [sumTSize, setSumTSize] = useState([]);
  const [sumTRate, setSumTRate] = useState([]);
  const [sumRent, setSumRent] = useState([]);
  const [sumOccu, setSumOccu] = useState([]);
  const [leadsvsDeals, setLeadsVsDeals] = useState([]);
  const [monthlyRevenue, setMonthlyRevenue] = useState([]);
  const [monthlyExpired, setMonthlyExpired] = useState([]);
  const [monthlyUnder, setMonthlyUnder] = useState([]);
  const [groupState, setGroupState] = useState([]);
  const [groupStateNew, setGroupStateNew] = useState([]);
  const [finalMap, setFinalMap] = useState([]);
  const [revenue, setRevenue] = useState(0);
  const [mapClicked, setMapClicked] = useState(null);
  const [chartdate, setChartDate] = useState(0);
  const [realtimeByState, setRealtimeByState] = useState([]);
  const [visibleLeft, setVisibleLeft] = useState(false);
  const [hoverLiveFeed, setHoverLiveFeed] = useState(false);
  const [viewLiveFeedsData, setViewLiveFeedsData] = useState(0);
  const [mapURL, setMapURL] = useState("");
  const [leadchartdate, setLeadChartDate] = useState(0);
  const [tokenExpired, setTokenExpired] = useState(false);
  const navigate = useNavigate();
  const [stateSelectedToShow, setStateSelectedToShow] = useState(null);
  const { handleAddAction } = useAddAction(userToken);
  const user = userData.data.user;
  const userid = String(user.id);
  const Org = user.organization;
  const subs = userData.data.subs[0];
  let subId = subs.id;

  useEffect(() => {
    getMarketData();
    getNewsData();
    getLiveData();
    if (selectedOrg === null) {
      setSelectedOrg({ agent_id: organization });
    }
    // getBarData();
  }, []);
  useEffect(() => {
    getMapData();
  }, [groupState]);
  // useEffect(()=>{
  //   if(mapClicked === null){
  //    getBarData();
  //   }else{
  //     getstatedetails(mapClicked,barchartdate);
  //   }
  // },[barchartdate])
  useEffect(() => {
    if (mapClicked !== null) {
      getstatedetails(mapClicked, barchartdate);
    }
    // else{
    //   getBarData();
    // }
  }, [mapClicked]);
  // for session time
  useEffect(() => {
    const date = moment();

    return () => {
      const endDate = moment();
      const item = {
        userid: userid,
        org: Org,
        subs_id: subId,
        page: "Market Intelligence",
        start_time: date,
        end_time: endDate,
        session: userToken,
      };
      axios
        .post(`${process.env.REACT_APP_USER_SESSION_TIMES}`, item, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then((response) => {
          console.log(response.config.data, "post success");
        })
        .catch((err) => console.log(err));
    };
  }, []);
  //   useEffect(()=>{
  //     if(tokenExpired === true){
  //       redirect()
  //     }
  //     },[tokenExpired])
  // const redirect =()=>{
  //   navigate('/');
  // }

  // useEffect(()=>{
  //   getOrg();
  //   setLOrg(org);
  // },[])
  // useEffect(()=>{
  //   setLOrg(org);
  //  },[selectedOrg])
  //  const getOrg = async () =>{
  //      await axios.get(`${process.env.REACT_APP_ORGANIZATION_LIST}`,
  //      {
  //        headers: {
  //          Authorization: `Bearer ${userToken}`,
  //        },
  //      }
  //      ).then((data) => {
  //        let dd = data.data;
  //        let ds = dd.sort(function (a, b) {
  //          if (a.agent_id < b.agent_id) {
  //            return -1;
  //           }
  //           if (a.agent_id > b.agent_id) {
  //             return 1;
  //           }
  //           return 0;
  //         });
  //        setOrgDetails(ds);
  //        setTokenExpired(false)

  //    })
  //    .catch((err) =>{
  //     let error = err.response.data.error;
  //     if(error.message === "This token has expired"){
  //      setTokenExpired(true);
  //      sessionStorage.setItem('logged',false)
  //     }
  //   });

  //  };
  //  const onOrgChange = (e) => {
  //    e.preventDefault();
  //    setSelectedOrg(e.value);
  //    setShowDropdown(!showDropdown);
  //    sessionStorage.setItem(
  //      "orgdata",
  //      JSON.stringify(e.value)
  //    );

  //  };
  const getLiveData = async () => {
    await axios
      .get(`${process.env.REACT_APP_LIVEFEEDS}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((data) => {
        let a = data.data;
        setLiveData(a);
        setTokenExpired(false);
      })
      .catch((err) => {
        console.log(err);
        // let error = err.response.data.error;
        // if(error.message === "This token has expired"){
        //  setTokenExpired(true);
        //  sessionStorage.setItem('logged',false)
        // }
      });
  };
  const getNewsData = async () => {
    await axios
      .get(`${process.env.REACT_APP_NEWSFEED}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((data) => {
        let a = data.data;
        setNewsData(a);
        setTokenExpired(false);
      })
      .catch((err) => {
        console.log(err);
        // let error = err.response.data.error;
        // if(error.message === "This token has expired"){
        //  setTokenExpired(true);
        //  sessionStorage.setItem('logged',false)
        // }
      });
  };
  const groupBy = (array, key) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  };
  // const getBarData = async(state) =>{
  //   let newDate = moment(barchartdate).format("YYYY-MM-DD")
  //    const months = [];
  //   for(let i = 0; i < 12; i++){
  //     // const date = new Date();
  //     // let d = date.setMonth(barchartdate?.getMonth() + i, 1);
  //     const d = new Date(barchartdate.getFullYear(), barchartdate.getMonth() + i, 1);
  //     let finalDate = moment(d).format('MMM-YY')
  //     months.push(finalDate);
  //     }
  //    let url = state !== undefined ? `${process.env.REACT_APP_MARKETINSIGHTS_LEADS_AGEING}?date=${newDate}&state=${state}` : `${process.env.REACT_APP_MARKETINSIGHTS_LEADS_AGEING}?date=${newDate}`;
  //   await axios.get(url,
  //   {
  //     headers: {
  //       Authorization: `Bearer ${userToken}`,
  //     },
  //   }
  //   ).then((data) => {
  //     let da = data.data;
  //     let i = da?.ageing.sort();
  //     let count = da?.count;
  //     let lgCount = count?.map(a => a.count*1);
  // let dc = [];
  //  months.forEach((d) => {
  //     let getdc = i?.find(item => moment(item.month).format("MMM-YY") === d)
  //     if(getdc){
  //     dc.push(getdc.count*1)
  //     }
  //     else {
  //       dc.push(0)
  //     }
  //   })
  //   setTokenExpired(false)
  //     setSumLeads([lgCount[0]]);
  //     setLeadsVsDeals(dc);
  //   })
  //   .catch((err) =>{(
  //     console.log(err))
  //     // let error = err.response.data.error;
  //     // if(error.message === "This token has expired"){
  //     //  setTokenExpired(true);
  //     //  sessionStorage.setItem('logged',false)
  //     // }
  //   });
  //   setLeadChartDate(months);

  // }

  function daysInMonth(month, year) {
    // Use 1 for January, 2 for February, etc.
    return new Date(year, month, 0).getDate();
  }
  function isWeekday(year, month, day) {
    var dayN = new Date(year, month - 1, day).getDay();
    return dayN != 0 && dayN != 6;
  }
  function getWeekdaysInMonth(month, year) {
    var days = daysInMonth(month, year);
    var weekdays = 0;
    for (var i = 0; i < days; i++) {
      if (isWeekday(year, month, i + 1)) weekdays++;
    }
    return weekdays;
  }
  const getMarketData = async () => {
    const months = [];
    for (let i = 2; i < 7; i++) {
      const date = new Date();
      let d = date.setMonth(currentDate.getMonth() - i, 1);
      let finalDate = moment(d).format("MMM");
      months.push(finalDate);
    }
    // let  numberofdays = [];
    // for(let i = 2; i < 7; i++){
    //   const date = new Date();
    //   let d = date.setMonth(currentDate.getMonth() - i, 1);
    //   let f = moment(d).format('MM-YYYY')
    //   let m = moment(d).format('M')
    //   let y = moment(d).format('YYYY')
    //   let finalDate = moment(d).format('MMM')
    //   months.push(finalDate);
    //   var weekdays = getWeekdaysInMonth(m, y);
    //   numberofdays.push(weekdays)
    //   }
    //   console.log(numberofdays,"checkdates")

    // let date = moment(currentDate).subtract(5, 'months').format('YYYY-MM');
    // let fdate = `${date}-01`
    // await axios.get(`${process.env.REACT_APP_MARKETINSIGHTS}?date=${fdate}`,
    await axios
      .get(`${process.env.REACT_APP_MARKETINSIGHTS}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((data) => {
        // let i = data.data.alldata.sort();
        // let vmap = data.data.map.sort();
        let i = data.data.sort();
        setTokenExpired(false);
        setMarketInsights(i);
        // const dataGroupedByDate = groupBy(i, "date");
        // const dataGroupedByState = groupBy(vmap, "property_state");

        const dataGroupedByDate = groupBy(i, "recording_month");
        const dataGroupedByState = groupBy(i, "property_state");
        const keys = Object.keys(dataGroupedByDate);
        setAllKey(keys);
        setGroup(dataGroupedByDate);
        let leads = [];
        let deals = [];
        let avgtransactionsize = [];
        let monthlySales = [];
        let avgtransactionrate = [];
        let avgrent = [];
        let avgoccu = [];
        let saleAmt = [];
        let FinalSaleAmount = [];
        let FinalTSize = [];
        let FinalTRate = [];
        let FinalRent = [];
        let FinalOccu = [];
        let leadsGen = [];
        let dealsGen = [];
        let newl = dataGroupedByDate;
        let newState = dataGroupedByState;
        for (var key in newl) {
          let saleamount = newl[key].map((d) => d.total_sales);
          let newS = saleamount.reduce((c, d) => c + d);
          saleAmt.push({ key: moment(key).format("MMM"), amt: newS });
          let leadsgenerated = newl[key].map((d) => d.leads_generated);
          let newLG = leadsgenerated.reduce((c, d) => c + d);
          leadsGen.push({ key: moment(key).format("MMM"), leads: newLG });
          let dealsgenerated = newl[key].map((d) => d.total_transactions);
          let newDeal = dealsgenerated.reduce((c, d) => c + d);
          dealsGen.push({ key: moment(key).format("MMM"), deals: newDeal });
          let transsize = newl[key].map((d) => d.avg_transaction_size / 51);
          let newTS = transsize.reduce((c, d) => c + d);
          avgtransactionsize.push({
            key: moment(key).format("MMM"),
            tsize: newTS,
          });

          let transrate = newl[key].map((d) => d.transaction_rate / 51);
          let newTR = transrate.reduce((c, d) => c + d);
          avgtransactionrate.push({
            key: moment(key).format("MMM"),
            trate: newTR,
          });

          let avgrentrate = newl[key].map((d) => d.avg_rental_rate / 51);
          let newAR = avgrentrate.reduce((c, d) => c + d);
          avgrent.push({ key: moment(key).format("MMM"), rentrate: newAR });

          let avgoccurate = newl[key].map((d) => d.avg_occupancy_rate / 51);
          let newAO = avgoccurate.reduce((c, d) => c + d);
          avgoccu.push({ key: moment(key).format("MMM"), occurate: newAO });
        }
        months.map((item) => {
          const matchedSaleAmt = saleAmt.find((obj) => obj.key === item);
          const matchedLeads = leadsGen.find((obj) => obj.key === item);
          const matchedDeals = dealsGen.find((obj) => obj.key === item);
          const matchedTSize = avgtransactionsize.find(
            (obj) => obj.key === item
          );
          const matchedTRate = avgtransactionrate.find(
            (obj) => obj.key === item
          );
          const matchedRent = avgrent.find((obj) => obj.key === item);
          const matchedOccu = avgoccu.find((obj) => obj.key === item);

          if (matchedSaleAmt) {
            FinalSaleAmount.push(Number(matchedSaleAmt.amt));
          } else {
            FinalSaleAmount.push(0);
          }
          if (matchedLeads) {
            leads.push(matchedLeads.leads);
          } else {
            leads.push(0);
          }
          if (matchedDeals) {
            deals.push(matchedDeals.deals);
          } else {
            deals.push(0);
          }
          if (matchedTSize) {
            FinalTSize.push(matchedTSize.tsize);
          } else {
            FinalTSize.push(0);
          }
          if (matchedTRate) {
            FinalTRate.push(matchedTRate.trate);
          } else {
            FinalTRate.push(0);
          }
          if (matchedRent) {
            FinalRent.push(matchedRent.rentrate);
          } else {
            FinalRent.push(0);
          }
          if (matchedOccu) {
            FinalOccu.push(matchedOccu.occurate);
          } else {
            FinalOccu.push(0);
          }
        });

        let monthlySR = [];
        for (var k in newState) {
          let ms = newState[k]
            .map((d) => d.total_sales)
            .reduce((c, d) => c + d);
          let mr = newState[k].map((d) => ({
            date: d.recording_month,
            stateAbb: d.property_state,
            amt: d.total_sales,
          }));
          monthlySales.push([k.toLowerCase(), ms]);
          monthlySR.push([k.toLowerCase(), mr]);
        }

        let changed = ownerstate.map((e) => {
          return [e[0].toLowerCase(), e[1]];
        });
        var prev = changed;
        var next = monthlySales;

        var hash = new Map();
        prev.concat(next).forEach(function (obj) {
          hash.set(obj[0], Object.assign(hash.get(obj[0]) || [], obj));
        });

        let mergedArray = Array.from(hash.values());
        let doneMerged = [];

        mergedArray.forEach((element) => {
          let fo = numberFormat(element[1]);
          doneMerged.push({
            "hc-key": "us-" + element[0],
            n: element[0].toUpperCase(),
            value: element[1],
            for: fo,
          });
        });
        if (doneMerged.length > 0) {
          setloader({ groupState: true });
        }

        let heightm =
          window.innerHeight > 1000
            ? window.innerHeight / 2 - 100
            : window.innerHeight > 620
            ? window.innerHeight / 2 - 60
            : window.innerHeight / 2 - 40;
        let width =
          window.innerWidth < 500
            ? window.innerWidth - 20
            : window.innerWidth / 2;
        let fch = window.innerHeight / 8;
        let bar = window.innerHeight / 4.5;
        setHeight([heightm, width, fch, bar]);
        setOldGroupState(monthlySales);
        setGroupState(doneMerged);

        let m = numberFormat(FinalSaleAmount);
        setRevenue(m);

        let sa = [];
        let dc = [];
        let lg = [];
        let ts = [];
        let tr = [];
        let rr = [];
        let or = [];

        months.forEach((d) => {
          let getre = i?.filter(
            (item) => moment(item.recording_month).format("MMM") === d
          );
          if (getre.length > 0) {
            let len = getre.length;
            let salesN = getre.map((a) => a.total_sales * 1);
            let addedSalesN = salesN.reduce((c, d) => c + d);
            let transN = getre.map((a) => a.total_transactions * 1);
            let addedTransN = transN.reduce((c, d) => c + d);
            let ATSN = getre.map((a) => (a.avg_transaction_size * 1) / len);
            let addedATSN = ATSN.reduce((c, d) => c + d);
            let AORN = getre.map((a) => (a.avg_occupancy_rate * 1) / len);
            let addedAORN = AORN.reduce((c, d) => c + d);
            let ARRN = getre.map((a) => (a.avg_rental_rate * 1) / len);
            let addedARRN = ARRN.reduce((c, d) => c + d);
            let transRateN = getre.map((a) => (a.transaction_rate * 1) / len);
            let addedTransRateN = transRateN.reduce((c, d) => c + d);
            sa.push(addedSalesN * 1);
            dc.push(addedTransN * 1);
            ts.push(addedATSN * 1);
            or.push(addedAORN * 1);
            rr.push(addedARRN * 1);
            tr.push(addedTransRateN * 1);
          } else {
            sa.push(0);
            dc.push(0);
            ts.push(0);
            or.push(0);
            rr.push(0);
            tr.push(0);
          }
        });

        setSumDeals(dc.reverse());
        setSumTSize(ts.reverse());
        setSumTRate(tr.reverse());
        setSumRent(rr.reverse());
        setSumOccu(or.reverse());
        setMonthlyRevenue(sa.reverse());
        setChartDate(months.reverse());
      })
      .catch((err) => {
        console.log(err);
        // let error = err.response.data.error;
        // if(error.message === "This token has expired"){
        //  setTokenExpired(true);
        //  sessionStorage.setItem('logged',false)
        // }
      });
  };
  const getMapData = () => {
    let data = [];
    let g = groupState;
    g.forEach((item) => {
      let name = item.n;
      let value = item.value;
      let amountString = item.for;
      let numberString = amountString.replace(/[^\d.-]/g, "");
      let amountNumber = parseFloat(numberString);
      let millionFormat = (amountNumber / 1000000).toFixed(2);
      data.push({
        "hc-key": "us-" + name.toLowerCase(),
        value: value === null ? 0 : value,
        n: item.n,
        main: millionFormat,
      });
    });
    setFinalMap(data);
  };
  const clearstate = () => {
    getMarketData();
    setMapClicked(null);
    setStateSelectedToShow(null);
    let addaction = {
      userid: userid,
      org: Org,
      subs_id: subId,
      page: "Market Intelligence",
      widget: "Map Filter Clear",
      actions: {
        actiontype: "clicked",
      },
    };
    handleAddAction(addaction);
  };
  const clicked = (e) => {
    setMapClicked(e);
    setStateSelectedToShow(e);
    console.log(e, "map click");
  };

  const getstatedetails = async (state, bcdate) => {
    // const months = [];
    // for(let i = 0; i < 5; i++){
    //   const date = new Date();
    //   let d = date.setMonth(bcdate?.getMonth() + i, 1);
    //   let finalDate = moment(d).format('MMM')
    //   months.push(finalDate);
    //   }
    // let newDate = moment(bcdate).format("YYYY-MM-DD");
    // await axios.get(`${process.env.REACT_APP_MARKETINSIGHTS}?date=${newDate}`,
    await axios
      .get(`${process.env.REACT_APP_MARKETINSIGHTS}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((data) => {
        let addaction = {
          userid: userid,
          org: Org,
          subs_id: subId,
          page: "Market Intelligence",
          widget: "Map",
          actions: {
            actiontype: "clicked",
            state: `${state}`,
          },
        };
        handleAddAction(addaction);

        setTokenExpired(false);

        let all = data.data;
        let i = data.data;
        let lead = all.lg * 1; //onstateclicklead
        let avgts = all.ats;
        let avgtr = all.atr;
        let avgrr = all.arr;
        let avgor = all.aor;
        let mrev = all.sa * 1;
        let mund = all.underContract;
        let mexp = all.expiredContract;
        let deals = [];
        let leads = [];
        let rev = [];
        let und = [];
        let expp = [];
        let ts = [];
        let tr = [];
        let rr = [];
        let or = [];
        let lg = [];
        let dc = [];
        // let tk = []
        const newStateData = all?.filter(
          (item) => item.property_state === state
        );
        chartdate.forEach((d) => {
          // tk.push({date:d,value:0})
          let ms = realtimeByState[state];
          let getre = newStateData?.filter(
            (item) => moment(item.recording_month).format("MMM") === d
          );
          if (getre.length > 0) {
            let salesN = getre.map((a) => a.total_sales * 1);
            let addedSalesN = salesN.reduce((c, d) => c + d);
            let transN = getre.map((a) => a.total_transactions * 1);
            let addedTransN = transN.reduce((c, d) => c + d);
            let ATSN = getre.map((a) => a.avg_transaction_size * 1);
            let addedATSN = ATSN.reduce((c, d) => c + d);
            let AORN = getre.map((a) => a.avg_occupancy_rate * 1);
            let addedAORN = AORN.reduce((c, d) => c + d);
            let ARRN = getre.map((a) => a.avg_rental_rate * 1);
            let addedARRN = ARRN.reduce((c, d) => c + d);
            let transRateN = getre.map((a) => a.transaction_rate * 1);
            let addedTransRateN = transRateN.reduce((c, d) => c + d);
            rev.push(addedSalesN * 1);
            deals.push(addedTransN * 1);
            ts.push(addedATSN * 1);
            or.push(addedAORN * 1);
            rr.push(addedARRN * 1);
            tr.push(addedTransRateN * 1);
          } else {
            rev.push(0);
            deals.push(0);
            ts.push(0);
            or.push(0);
            rr.push(0);
            tr.push(0);
          }
        });
        console.log(deals, or, "checkDeals");
        // months.forEach((d) => {
        // let getlg = i.find(item => moment(item.date).format("MMM") === d)
        // if(getlg){
        // lg.push(getlg.lg*1)
        // dc.push(getlg.dc*1)
        // }
        // else {
        //   lg.push(0)
        //   dc.push(0)
        // }

        // })
        // setSumLeads([lg[0]]);
        // setLeadsVsDeals(dc);
        // setLeadChartDate(months);

        // getBarData(state);

        setSumTSize(ts);
        setSumTRate(tr);
        setSumRent(rr);
        setSumOccu(or);
        setSumDeals(deals);
        setMonthlyRevenue(rev);
        // setMonthlyUnder(und);
        // setMonthlyExpired(expp)
      })
      .catch((err) => {
        console.log(err);
        // let error = err.response.data.error;
        // if(error.message === "This token has expired"){
        //  setTokenExpired(true);
        //  sessionStorage.setItem('logged',false)
        // }
      });
  };
  // const [showDropdown, setShowDropdown] = useState(false);
  // const handleDropdownClick = () => {
  //   setShowDropdown(!showDropdown);
  // };
  const viewlivepropdetails = (d) => {
    setViewLiveFeedsData(d);
    setVisibleLeft(true);
    getmapurl(d);
    let addaction = {
      userid: userid,
      org: Org,
      subs_id: subId,
      page: "Market Intelligence",
      widget: "Live Feeds Ticker",
      actions: {
        actiontype: "clicked",
        property_name: `${d.nedl_property_name}`,
      },
    };
    handleAddAction(addaction);
  };

  const getmapurl = (newData) => {
    let prop =
      newData.nedl_property_name !== null ? newData.nedl_property_name : null;
    let address =
      newData.property_address !== null ? newData.property_address : null;
    let lat = newData.latitude !== null ? newData.latitude : null;
    let longg = newData.longitude !== null ? newData.longitude : null;
    let mapurl = "";
    if (prop !== null && prop !== undefined) {
      let p = prop.toString();
      let formattedProp = p.replace(/\s/g, "+");
      mapurl = `${formattedProp}`;
    }
    if (address !== null && address !== undefined && prop === null) {
      let a = address.toString();
      let formattedAddress = a.replace(/\s/g, "+");
      mapurl = `${formattedAddress}`;
    }
    if (address !== null && address !== undefined && prop !== null) {
      let a = address.toString();
      let formattedAddress = a.replace(/\s/g, "+");
      mapurl = `${mapurl},${formattedAddress}`;
    }
    if (
      lat !== null &&
      lat !== undefined &&
      longg !== null &&
      longg !== undefined
    ) {
      mapurl = `${mapurl}&center=${lat},${longg}`;
    }
    setMapURL(mapurl);
  };

  return (
    <>
      <div className="overflowscroll">
        {liveData && liveData.length >= 1 ? (
          <>
            <img
              src="\images\lattest\liveImage.png"
              className="live"
              width="90px"
              alt="liveLeads"
            />
            <div className="livecontainer">
              {
                liveData?.map((d) => (
                  <Stack
                    key={d.id}
                    sx={{
                      display: "flex",
                      marginRight: "5px",
                      alignItems: "center",
                      flexDirection: "row",
                      gap: 1,
                      flex: "1 0 auto",
                      minWidth: "200px",
                      maxWidth: "500px",
                      cursor: "pointer",
                      bgcolor: "#fff",
                    }}
                    onClick={() => viewlivepropdetails(d)}
                  >
                    <img src="/images/lattest/dot.svg" alt="dot" width="10px" />

                    <Typography
                      variant="body1"
                      color="orange"
                      fontWeight={600}
                      sx={{
                        fontSize: ".7rem",
                      }}
                    >
                      {d.nedl_property_name},
                    </Typography>

                    <Typography
                      variant="body2"
                      fontWeight={600}
                      sx={{
                        fontSize: ".7rem",
                      }}
                    >
                      { (d.document_amount / 1000000).toFixed(2) < 0.01
                       ? ""  
                       : `$${(d.document_amount / 1000000).toFixed(2)}M`
                        }
                    </Typography>

                    <Typography
                      variant="caption"
                      fontWeight={400}
                      sx={{
                        fontSize: ".7rem",
                      }}
                    >
                      {d.document_recorded_date
                        ? moment(d.document_recorded_date).format("MM-DD-YYYY")
                        : ","}
                    </Typography>
                  </Stack>
                ))}
            </div>
          </>
        ) : (
          <>
            {" "}
            <Skeleton className="liveloader" height="85%" />{" "}
          </>
        )}
      </div>
      {/* <div className="overflowtop">
              <img src="\images\lattest\dot.svg" className="dot" width="10px" alt="liveFedds Dots"/>
             <div onClick={() => viewlivepropdetails(d)} className="livedata" key={`${d.nedl_property_name}`}><label className="orange pointer">{`${d.nedl_property_name},`}</label>
               {d.document_amount ? <>
                <span className="numberfont font13 fontweight600 ml10">{`$${((d.document_amount)/1000000).toFixed(2)}M,`}</span>
              </>:
              <span className="numberfont font13 fontweight600">,</span>
              }
                {d.document_recorded_date ? <>
                <span className="numberfont font13 fontweight600 ml10">{moment(d.document_recorded_date).format("DD MMM YYYY")}</span>
              </>:
              <span className="numberfont font13 fontweight600" >,</span>
              }
              </div> 
              </div> */}
      <div className="marketpagecontent">
        <div className="mchartsFourchart ">
          {monthlyRevenue.length >= 1 && sumDeals.length >= 1 ? (
            <MI4Cards
              // expiredContract={monthlyExpired} underContract={monthlyUnder} labels={keyv}
              revenuedata={monthlyRevenue}
              height={height[2]}
              dealClosed={sumDeals}
              dateLabel={chartdate}
              tsize={sumTSize}
              trate={sumTRate}
              rent={sumRent}
              occu={sumOccu}
            />
          ) : (
            <>
              <NormalLoader type="type2" count={6} />
            </>
          )}
        </div>
        <div className="michartContainer">
          <div className="mapsidecontainer chartFlexContainer">
            <div className=" mcharts feeds">
              {newsData.length >= 5 ? (
                <>
                  <p className="title">Feeds</p>
                  <Newsfeed feeds={newsData} style={{ borderRadius: "10px" }} />
                </>
              ) : (
                <>
                  <FeedsLoader />{" "}
                </>
              )}
            </div>
            {/* <div className="mcharts milinechart">
          {sumleads.length >= 1 && leadsvsDeals.length >= 1  ?
          <>
                <p className="title">Leads Ageing 
                  {stateSelectedToShow !== null  && stateSelectedToShow !== undefined ? <label className="selectedStateStyle">{`State Selected : ${stateSelectedToShow }`}</label>: null}
                <Calendar id="monthpicker" value={barchartdate} className="micalendar"
                 onChange={(e) => setBarChartDate(e.value)} view="month" dateFormat="MM yy" 
                 yearNavigator yearRange="2010:2030" showIcon icon="pi pi-angle-down"
                 />
                </p>
                <MIBar  leads={sumleads} deals={leadsvsDeals} labels={leadchartdate} date={barchartdate} height={height}>
                 </MIBar>
                </> :
                <> <NormalLoader type="type2" count={1}  /> </>}
          </div> */}
          </div>
          <div className="mcharts mimap chartFlexContainer">
            {finalMap.length >= 1 ? (
              <>
                <p className="title">
                  Sales by State
                  <div>
                    {stateSelectedToShow !== null &&
                    stateSelectedToShow !== undefined ? (
                      <label className="selectedStateStyle tdnone">{`State Selected : ${stateSelectedToShow}`}</label>
                    ) : null}
                    <button className="clearbtn" onClick={clearstate}>
                      Clear
                    </button>
                  </div>
                </p>
                <div className="mapcontainer">
                  <MIUSMap
                    stateData={finalMap}
                    height={height}
                    clicked={clicked}
                  />
                </div>
              </>
            ) : (
              <>
                {" "}
                <NormalLoader type="type1" count={1} contentHeight="100%" />
              </>
            )}
          </div>
        </div>
      </div>
      <Sidebar
        style={{ width: "30%" }}
        visible={visibleLeft}
        position="right"
        onHide={() => setVisibleLeft(false)}
        baseZIndex={1001}
        className="leads-sidebar"
      >
        <MISidebar data={viewLiveFeedsData} mapURL={mapURL} />{" "}
      </Sidebar>
      {/* <Chat /> */}
    </>
  );
};

export default MarketIntelligence;

// import React, { useState, useEffect, useCallback } from "react";
// import { Toaster, toast } from "react-hot-toast";
// import { Card, CardContent, IconButton, Typography, Box } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import ErrorIcon from "@mui/icons-material/Error";
// import WarningIcon from "@mui/icons-material/Warning";
// import InfoIcon from "@mui/icons-material/Info";

// const ToastProvider = ({ children }) => {
//   const [toasts, setToasts] = useState([]);

//   // UseCallback to ensure the state is updated after rendering
//   const handleNewToast = useCallback((t) => {
//     setToasts((prev) => [
//       ...prev,
//       { id: t.id, message: t.message, type: t.type || "info" },
//     ]);
//     setTimeout(() => {
//       setToasts((prev) => prev.filter((toast) => toast.id !== t.id));
//     }, 4000);
//   }, []);

//   useEffect(() => {
//     // Subscribe to the toast event
//     const unsubscribe = toast((t) => {
//       handleNewToast(t);
//     });
//     return unsubscribe;
//   }, [handleNewToast]);

//   // Function to manually remove a toast
//   const removeToast = (id) => {
//     setToasts((prev) => prev.filter((toast) => toast.id !== id));
//   };

//   // Define toast types and their corresponding styles/icons
//   const toastTypes = {
//     success: { bgColor: "#4caf50", icon: <CheckCircleIcon /> },
//     error: { bgColor: "#f44336", icon: <ErrorIcon /> },
//     warning: { bgColor: "#ff9800", icon: <WarningIcon /> },
//     info: { bgColor: "#2196f3", icon: <InfoIcon /> },
//   };

//   return (
//     <div>
//       <Toaster position="top-right" reverseOrder={false} />

//       {/* Toast Stack Container */}
//       <Box
//         sx={{
//           position: "fixed",
//           top: 20,
//           right: 20,
//           width: 350,
//           zIndex: 1300,
//           display: "flex",
//           flexDirection: "column",
//           gap: 1,
//         }}
//       >
//         {toasts.map((t) => {
//           const toastType = toastTypes[t.type] || toastTypes.info; // Default to 'info' type

//           return (
//             <Card
//               key={`${t.id}-${Date.now()}`} // Ensure key is unique by combining id and timestamp
//               sx={{
//                 backgroundColor: toastType.bgColor,
//                 color: "white",
//                 display: "flex",
//                 alignItems: "center",
//                 padding: "8px 12px",
//                 borderRadius: 2,
//               }}
//             >
//               {toastType.icon}
//               <CardContent sx={{ flexGrow: 1 }}>
//                 {/* Ensure the message is a valid ReactNode */}
//                 <Typography variant="body2">
//                   {t.message || "No message"}
//                 </Typography>
//               </CardContent>
//               <IconButton
//                 onClick={() => removeToast(t.id)}
//                 size="small"
//                 sx={{ color: "white" }}
//               >
//                 <CloseIcon fontSize="small" />
//               </IconButton>
//             </Card>
//           );
//         })}
//       </Box>

//       {children}
//     </div>
//   );
// };

// export default ToastProvider;
// components/CustomToaster.js

import { Toaster, ToastBar, toast } from "react-hot-toast";
import ThumbUpAltSharpIcon from "@mui/icons-material/ThumbUpAltSharp";
import CloseIcon from "@mui/icons-material/Close"; // Import a close icon
import { Error } from "@mui/icons-material";
import { Typography, Box } from "@mui/material";

const ToasterLayout = () => {
  return (
    <Toaster
      // Position of the toasts
      position="top-right"
      reverseOrder={false} // New toasts appear at the bottom of the stack
      gutter={2} // Spacing between toasts
      containerStyle={{
        width: "90%",

        padding: 0,
        margin: "auto",
        // Adjust the width of the toasts
      }}
      toastOptions={{
        duration: 5000, // Default duration for toasts
        style: {
          background: "#363636",
          color: "#fff",
          padding: "5px 10px",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
          fontSize: ".7rem",
          lineHeight: ".8rem",
          width: "300px",
        },
        success: {
          duration: 3000,
          style: {
            background: "#4BB543",
            color: "#fff",
          },
          iconTheme: {
            primary: "#4caf50",
            secondary: "#fff",
          },
          icon: <ThumbUpAltSharpIcon />,
        },
        error: {
          duration: 4000,
          style: {
            background: "#FF3333",
          },
          iconTheme: {
            primary: "#ff4b4b",
            secondary: "#fff",
          },
          icon: <Error />,
        },
        loading: {
          style: {
            background: "#FF3333",
          },
          duration: Infinity, // Loading toasts don't auto-close
        },
        custom: {
          duration: 6000,
          icon: <Error />, // Default duration for custom toasts
        },
      }}
    >
      {(t) => (
        <ToastBar
          toast={t}
          style={{
            ...t.style,
            animation: t.visible
              ? "custom-enter 1s ease"
              : "custom-exit 1s ease forwards",
          }}
        >
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== "loading" && (
                <button
                  onClick={() => toast.dismiss(t.id)}
                  style={{
                    background: "transparent",
                    border: "none",
                    color: "#fff",
                    cursor: "pointer",
                    marginLeft: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CloseIcon fontSize="small" /> {/* Use a close icon */}
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default ToasterLayout;

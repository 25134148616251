import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SideNav = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const userData = JSON.parse(sessionStorage?.getItem("userdata"));
  const data = userData?.data;
  const role = data?.user.role;
  return (
    <>
      <div className="leftmenu">
        <div className="sideicons">
          <img className="logo" src="/images/lattest/nedl_rgb.png" />
          <NavLink
            exact
            to="/market"
            className="lmenuitemac"
            activeclassname="lmenuitemactiveac"
          >
            {pathName === "/market" ? (
              <div className="from_bottomac_active">
                <img
                  src="\images\lattest\market intelligence_active.svg"
                  alt="leads"
                />
                <div className="pageiconlabel">
                  <label>
                    Market <br /> Intelligence
                  </label>
                </div>
              </div>
            ) : (
              <div className="from_bottomac">
                <img
                  src="\images\lattest\market intelligence_inactive.svg"
                  alt="leads"
                />
                <div className="pageiconlabelhover">
                  <label>
                    Market <br />
                    Intelligence
                  </label>
                </div>
              </div>
            )}
          </NavLink>
          <NavLink
            exact
            to="/leads"
            className="lmenuitemac"
            activeclassname="lmenuitemactiveac"
          >
            {pathName === "/leads" ? (
              <div className="from_bottomac_active">
                <img src="\images\lattest\leadsactive.svg" alt="leads" />
                <div className="pageiconlabel">
                  <label>
                    Intelligent <br /> Leads
                  </label>
                </div>
              </div>
            ) : (
              <div className="from_bottomac">
                <img src="\images\lattest\leadsinactive.svg" alt="leads" />
                <div className="pageiconlabelhover">
                  <label>
                    Intelligent <br />
                    Leads
                  </label>
                </div>
              </div>
            )}
          </NavLink>

          <NavLink
            exact
            to="/datahub"
            className="lmenuitemac"
            activeclassname="lmenuitemactiveac"
          >
            {pathName === "/datahub" ? (
              <div className="from_bottomac_active">
                <img
                  src="\images\lattest\buyers n sellers_active.svg"
                  alt="leads"
                />
                <div className="pageiconlabel">
                  <label>Data Hub</label>
                </div>
              </div>
            ) : (
              <div className="from_bottomac">
                <img
                  src="\images\lattest\buyers n sellers_inactive.svg"
                  alt="leads"
                />
                <div className="pageiconlabelhover">
                  <label>Data Hub</label>
                </div>
              </div>
            )}
          </NavLink>

          {role === 1 ? (
            <>
              <NavLink
                exact
                to="/rbac"
                className="lmenuitemac"
                activeclassname="lmenuitemactiveac"
              >
                {pathName === "/rbac" ? (
                  <div className="from_bottomac_active">
                    <img src="\images\lattest\Access_active.svg" alt="leads" />
                    <div className="pageiconlabel">
                      <label>
                        Access <br />
                        Control
                      </label>
                    </div>
                  </div>
                ) : (
                  <div className="from_bottomac">
                    <img
                      src="\images\lattest\Access_inactive.svg"
                      alt="leads"
                    />
                    <div className="pageiconlabelhover">
                      <label>
                        Access <br />
                        Control
                      </label>
                    </div>
                  </div>
                )}
              </NavLink>
            </>
          ) : null}
        </div>
        <div className="sideiconsHelp"></div>
      </div>
    </>
  );
};

export default SideNav;

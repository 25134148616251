import {
  BrowserRouter as Router,
  Routes,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./App.css";
// import BuyersSellersnew from "./pages/MarketInsights/buyernsellersnew";
// import ResetPasswordNew from "./pages/resetpasswordnew";
import ForgotPasswordNew from "./pages/forgotpassword";
// import RbNew from "./pages/MarketInsights/reportbuildernew";
import MarketIntelligence from "./pages/MarketIntelligence/marketIntelligence";
import IntelligentLeads from "./pages/IntelligentLeads/intelligentLeads";
import DataHub from "./pages/Datahub/datahub";
import UserEngagement from "./pages/UserEngagement/userEngagement";
import Rbac from "./pages/Rbac/rbac";
// import OwnershipGroup from "./pages/MarketInsights/ownershipgroup";
import axios from "axios";
import VerifyMail from "./pages/verifymail";
import VerifyPassword from "./pages/verifypasswordlogin";
import AddPassword from "./pages/addpasswordlogin";
import PrivateRoutes from "./store";
import PageNotFound from "./pages/PageNotFound";
import NoSubscription from "./pages/MarketInsights/NoSubscription";
import SubscriptionForm from "./pages/Stripe/SubscriptionForm";
import CompletePage from "./pages/Stripe/CompletePage";
import AddSubUserForm from "./pages/Stripe/AddSubUserForm";
import ToastProvider from "./Layout/ToastProvider";
// import Form from "./pages/Form/Form";
function App() {
  const useremail = sessionStorage.getItem("useremail");

  useEffect(() => {
    let l = sessionStorage.getItem("logged");
    const handleTabClose = (event) => {
      if (l === "true") {
        handleLogout(useremail);
      }
    };
    window.addEventListener("unload", handleTabClose);
    return () => {
      window.removeEventListener("unload", handleTabClose);
    };
  }, []);
  const handleLogout = async (email) => {
    const newItem = {
      email: email,
    };
    await axios
      .post(`${process.env.REACT_APP_USER_LOGOUT}`, newItem)
      .then((res) => {
        if (res.status === 200) {
          console.log("logged out successfully");
          sessionStorage.setItem("logged", false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Router>
   
      <div className="App">
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route element={<MarketIntelligence />} path="/market" index />
            <Route element={<IntelligentLeads />} path="/leads" />
            <Route element={<UserEngagement />} path="/user" />
            <Route element={<DataHub />} path="/datahub" />
            <Route element={<Rbac />} path="/rbac" />
            {/* <Route element={<Form/>} path="/form"/> */}
            {/* <Route element={<BuyersSellersnew/>} path="/buyers"/> */}
            {/* <Route element={<OwnershipGroup/>} path="/ownershipgroup"/> */}
          </Route>
          <Route path="/" element={<VerifyMail />} />
          <Route path="/login" element={<VerifyPassword />} />
          <Route path="/addpassword" element={<AddPassword />} />
          <Route exact path="/forgotpassword" element={<ForgotPasswordNew />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/nosubscription" element={<NoSubscription />} />
          <Route path="/subscription" element={<SubscriptionForm />} />

          {/* <Route path="/subscription/:priceId" element={<AddSubUserForm />} /> */}
          {/* <Route path="/subscription/:priceId" element={<AddSubUserForm />} /> */}
          <Route path="/complete" element={<CompletePage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import { RadioButton } from "primereact/radiobutton";
import { Avatar } from "primereact/avatar";
// import "./StripeForm.css";
import AddSubUserForm from "./AddSubUserForm";
import { Divider } from "primereact/divider";
import { InputSwitch } from "primereact/inputswitch";
import "primereact/resources/primereact.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "@mui/material";
import SubscriptionLayout from "../../Layout/subscriptionLayout";
import axios from "axios";
import Loader from "../../components/AAANEWCOMPONENT/Loader/Loader";
const SubscriptionForm = () => {
  const [checked2, setChecked2] = useState(false);
  const [subscriptionPlanMSAMonthly, setSubscriptionPlanMSAMonthly] =
    useState("true");
  const [subscriptionPlanRegionMonthly, setSubscriptionPlanRegionMonthly] =
    useState("true");
  const [subscriptionPlanALLMonthly, setSubscriptionPlanALLMonthly] =
    useState("true");
  const [finalAmt, setFinalAmt] = useState(null);
  const [productCoupon, setProductCoupon] = useState(null);
  const [finalSubscriptionFor, setFinalSubscriptionFor] = useState(null);
  const [finalPlan, setFinalPlan] = useState(null);
  const [showSelectSubForm, setShowSelectSubForm] = useState(false);
  const [msaHover, setMsaHover] = useState(false);
  const [regionHover, setRegionHover] = useState(false);
  const [AllHover, setAllHover] = useState(false);
  const [customHover, setCustomHover] = useState(false);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true); // Tracks the loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Simulate an API call to fetch productCoupon
        const response = await axios.get(
          `${process.env.REACT_APP_STRIPE_PRODUCTS_PRICE_COUPON}`
        );
        const data = await response.data;

        setProductCoupon(data); // Set the fetched data
      } catch (error) {
        console.error("Error fetching productCoupon:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchData();
  },[]);
let MSamonth;
let MSaYear;
let regionMonth;
let regionYear;
let allMonth;
let allYear;

if(productCoupon){
   const mm = productCoupon?.data.filter((product) =>
     product.name.includes("MSA Monthly")
   );
   MSamonth = mm[0];
   const my = productCoupon?.data.filter((product) =>
     product.name.includes("MSA Annually")
   );
    MSaYear = my[0];
   const rm = productCoupon?.data.filter((product) =>
     product.name.includes("Region Monthly")
   );
    regionMonth = rm[0];
   const ry = productCoupon?.data.filter((product) =>
     product.name.includes("Region Annually")
   );
    regionYear = ry[0];
   const am = productCoupon?.data.filter((product) =>
     product.name.includes("Nationwide Monthly")
   );
    allMonth = am[0];
   const ay = productCoupon?.data.filter((product) =>
     product.name.includes("Nationwide Annually")
   );
    allYear = ay[0];
}

 console.log({MSaYear,MSamonth})

  const handleMSASubscribe = () => {
   
    const amount =
      subscriptionPlanMSAMonthly === "true"
        ? MSamonth.price.amount / 100
        : MSaYear.price.amount / 100;
    const plan = subscriptionPlanMSAMonthly === "true" ? "monthly" : "yearly";
    const productd = subscriptionPlanMSAMonthly === "true" ? MSamonth : MSaYear;
    setFinalSubscriptionFor("MSA");
    // const urlNo = subscriptionPlanALLMonthly === "true" ? 1 : 4;
    setFinalAmt(amount);
    setFinalPlan(plan);
    setProduct(productd);
    setShowSelectSubForm(true);
  
  };

  const handleRegionSubscribe = () => {
    const amount =
      subscriptionPlanRegionMonthly === "true"
        ? regionMonth.price.amount / 100
        : regionYear.price.amount / 100;
    const p =
      subscriptionPlanRegionMonthly === "true"
        ? regionMonth
        : regionYear;
    const plan =
      subscriptionPlanRegionMonthly === "true" ? "monthly" : "yearly";
    setFinalSubscriptionFor("Region");
    // const urlNo = subscriptionPlanALLMonthly === "true" ? 2 : 5;
    setFinalAmt(amount);
    setFinalPlan(plan);
    setProduct(p);
    setShowSelectSubForm(true);
  
  };

  const handleAllSubscribe = () => {
    const amount =
      subscriptionPlanALLMonthly === "true"
        ? productCoupon.data[1].price.amount / 100
        : productCoupon.data[0].price.amount / 100;
    const p =
      subscriptionPlanALLMonthly === "true"
        ? productCoupon.data[1]
        : productCoupon.data[0];
    const plan = subscriptionPlanALLMonthly === "true" ? "monthly" : "yearly";
    // const urlNo = subscriptionPlanALLMonthly === "true" ? 3 : 6;
    setFinalSubscriptionFor("All");
    setFinalAmt(amount);
    setFinalPlan(plan);
    setProduct(p);
    setShowSelectSubForm(true);
    // let url = `/subscription/${urlNo}`;

    // navigate(url, {
    //   state: {
    //     subamount: amount,
    //     subplan: plan,
    //     subsubscriptionFor: "All",
    //     product: product,
    //     submsaMonthly: productCoupon.data[5].price.amount / 100,
    //     submsaYearly: productCoupon.data[4].price.amount / 100,
    //     subregionMonthly: productCoupon.data[3].price.amount / 100,
    //     subregionYearly: productCoupon.data[2].price.amount / 100,
    //     suballMonthly: productCoupon.data[1].price.amount / 100,
    //     suballYearly: productCoupon.data[0].price.amount / 100,
    //     clicked: "clicked",
    //   },
    // });
  };
  const handleLetsTalk = () => {
    window.location.href = "https://nedl.us/enterprise/";
  };
  useEffect(() => {
    handleAnnualPricing();
  }, [checked2]);
  const handleAnnualPricing = () => {
    if (checked2 === false) {
      setSubscriptionPlanMSAMonthly("true");
      setSubscriptionPlanRegionMonthly("true");
      setSubscriptionPlanALLMonthly("true");
    } else {
      setSubscriptionPlanMSAMonthly("false");
      setSubscriptionPlanRegionMonthly("false");
      setSubscriptionPlanALLMonthly("false");
    }
  };
  const msaheader = productCoupon && (
    <div className={msaHover ? "card-header card-header-hover" : "card-header"}>
      <div className="card-title">
        <img
          className="card-header-img"
          src={
            msaHover
              ? "/images/payment/msahovericon.png"
              : "/images/payment/msanormalicon.png"
          }
          alt=""
        />
        <label>
          {subscriptionPlanMSAMonthly === "true"
            ? MSamonth.name
            : MSaYear.name}
        </label>
      </div>
      <h1 class="card-pricing">
        <span class="dollar-sign">$</span>
        <span class="number">
          {subscriptionPlanMSAMonthly === "true"
            ? MSamonth?.price?.amount / 100
            : MSaYear?.price?.amount / 100}
        </span>
        <span
          class={
            subscriptionPlanMSAMonthly === "true" ? "per-month" : "per-year"
          }
        >
          {subscriptionPlanMSAMonthly === "true" ? "/per month" : "/per year"}
        </span>
      </h1>
      {/* {checked2 === true ? (
        <label
          className={
            msaHover ? "strikeoutvalue strikeoutvalue-hover" : "strikeoutvalue"
          }
        >{`$${msaPrice[2]}`}</label>
      ) : null} */}

      <button
        onClick={handleMSASubscribe}
        className={
          msaHover ? "subscribe-btn subscribe-btn-hover" : "subscribe-btn"
        }
      >
        Subscribe Now
      </button>
    </div>
  );
  const regionheader = productCoupon && (
    <>
      <div
        className={
          regionHover ? "mostPopular-hover mostPopular" : "mostPopular"
        }
      >
        Most Popular
      </div>
      <div
        className={
          regionHover ? "card-header card-header-hover" : "card-header"
        }
      >
        <div style={{ paddingTop: "10px" }} className="card-title">
          <img
            className="card-header-img"
            src={
              regionHover
                ? "/images/payment/regionhovericon.png"
                : "/images/payment/regionnormalicon.png"
            }
            alt=""
          />
          <label>
            {subscriptionPlanRegionMonthly === "true"
              ? regionMonth.name
              : regionYear.name}
          </label>
        </div>
        <h1 class="card-pricing">
          <span class="dollar-sign">$</span>
          <span class="number">
            {subscriptionPlanRegionMonthly === "true"
              ? regionMonth.price.amount / 100
              : regionYear.price.amount / 100}
          </span>
          <span
            class={
              subscriptionPlanRegionMonthly === "true"
                ? "per-month"
                : "per-year"
            }
          >
            {subscriptionPlanRegionMonthly === "true"
              ? "/per month"
              : "/per year"}
          </span>
        </h1>
        {/* {checked2 === true ? (
          <label
            className={
              regionHover
                ? "strikeoutvalue strikeoutvalue-hover"
                : "strikeoutvalue"
            }
          >{`$${regionPrice[2]}`}</label>
        ) : null} */}

        <button
          onClick={handleRegionSubscribe}
          className={
            regionHover ? "subscribe-btn subscribe-btn-hover" : "subscribe-btn"
          }
        >
          Subscribe Now
        </button>
      </div>
    </>
  );
  const allheader = productCoupon && (
    <div className={AllHover ? "card-header card-header-hover" : "card-header"}>
      <div className="card-title">
        <img
          className="card-header-img"
          src={
            AllHover
              ? "/images/payment/allhovericon.png"
              : "/images/payment/allnormalicon.png"
          }
          alt=""
        />
        <label>
          {subscriptionPlanALLMonthly === "true"
            ? allMonth.name
            : allYear.name}
        </label>
      </div>
      <h1 class="card-pricing">
        <span class="dollar-sign">$</span>
        <span class="number">
          {subscriptionPlanALLMonthly === "true"
            ? allMonth.price.amount / 100
            : allYear.price.amount / 100}
        </span>
        <span
          class={
            subscriptionPlanALLMonthly === "true" ? "per-month" : "per-year"
          }
        >
          {subscriptionPlanALLMonthly === "true" ? "/per month" : "/per year"}
        </span>
      </h1>
      {/* {checked2 === true ? (
        <label
          className={
            AllHover ? "strikeoutvalue strikeoutvalue-hover" : "strikeoutvalue"
          }
        >{`$${allPrice[2]}`}</label>
      ) : null} */}

      <button
        onClick={handleAllSubscribe}
        className={
          AllHover ? "subscribe-btn subscribe-btn-hover" : "subscribe-btn"
        }
      >
        Subscribe Now
      </button>
    </div>
  );
  const customheader = (
    <div
      className={customHover ? "card-header card-header-hover" : "card-header"}
    >
      <div
        className={
          checked2 === false
            ? "card-title-custom"
            : "card-title-custom annual-card-title-custom"
        }
      >
        <img
          className="card-header-img"
          src={
            customHover
              ? "/images/payment/enterprisehovericon.png"
              : "/images/payment/enterprisenormalicon.png"
          }
          alt=""
        />
        <label>Enterprise</label>
      </div>
      <button
        onClick={handleLetsTalk}
        className={
          customHover ? "subscribe-btn subscribe-btn-hover" : "subscribe-btn"
        }
      >
        Let's Talk
      </button>
    </div>
  );

  const msafooter = (
    <div>
      <label
        className={
          msaHover ? "benefitslabel benefitslabel-hover" : "benefitslabel"
        }
      >
        Benefits Include:
      </label>
      <ul
        className={msaHover ? "custom-list custom-hover-list" : "custom-list"}
        style={{ margin: "0", padding: "0" }}
      >
        <li>
          <img
            src={
              msaHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          1 License
        </li>
        <li>
          <img
            src={
              msaHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          1 Metropolitan Statistical Area
        </li>
        <li>
          <img
            src={
              msaHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Unlimited Intelligent Leads
        </li>
        <li>
          <img
            src={
              msaHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Recommended Buyers
        </li>
        <li>
          <img
            src={
              msaHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Deal Pipeline Management
        </li>
        <li>
          <img
            src={
              msaHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Market Intelligence
        </li>
      </ul>
    </div>
    // <button onClick={handleMSASubscribe}className={msaHover ? "subscribe-btn subscribe-btn-hover" : "subscribe-btn"}>GET STARTED</button>
  );
  const regionfooter = (
    <div>
      <label
        className={
          regionHover ? "benefitslabel benefitslabel-hover" : "benefitslabel"
        }
      >
        Benefits Include:
      </label>
      <ul
        className={
          regionHover ? "custom-list custom-hover-list" : "custom-list"
        }
        style={{ margin: "0", padding: "0" }}
      >
        <li>
          <img
            src={
              regionHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          1 License
        </li>
        <li>
          <img
            src={
              regionHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          1 Region
        </li>
        <li>
          <img
            src={
              regionHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Unlimited Intelligent Leads
        </li>
        <li>
          <img
            src={
              regionHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Recommended Buyers
        </li>
        <li>
          <img
            src={
              regionHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Deal Pipeline Management
        </li>
        <li>
          <img
            src={
              regionHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Market Intelligence
        </li>
      </ul>
    </div>
  );
  const allfooter = (
    <div>
      <label
        className={
          AllHover ? "benefitslabel benefitslabel-hover" : "benefitslabel"
        }
      >
        Benefits Include:
      </label>
      <ul
        className={AllHover ? "custom-list custom-hover-list" : "custom-list"}
        style={{ margin: "0", padding: "0" }}
      >
        <li>
          <img
            src={
              AllHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          1 License
        </li>
        <li>
          <img
            src={
              AllHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          All US Multifamily Properties
        </li>
        <li>
          <img
            src={
              AllHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Unlimited Intelligent Leads
        </li>
        <li>
          <img
            src={
              AllHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Recommended Buyers
        </li>
        <li>
          <img
            src={
              AllHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Deal Pipeline Management
        </li>
        <li>
          <img
            src={
              AllHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Market Intelligence
        </li>
      </ul>
    </div>
  );
  const customfooter = (
    <div className={checked2 === true ? "spacing-custom-annual" : null}>
      <label
        className={
          customHover ? "benefitslabel benefitslabel-hover" : "benefitslabel"
        }
      >
        Benefits Include:
      </label>
      <ul
        className={
          customHover ? "custom-list custom-hover-list" : "custom-list"
        }
        style={{ margin: "0", padding: "0" }}
      >
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Multiple Licenses
        </li>
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Geography of Choice
        </li>
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Professional Services
        </li>
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Dedicated Accounted Team
        </li>
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Dedicated Support Team
        </li>
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Unlimited Intelligent Leads
        </li>
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Recommended Buyers
        </li>
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Deal Pipeline Management
        </li>
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Market Intelligence
        </li>
      </ul>
    </div>
  );
  const handleLabelClick = () => {
    setChecked2((prevChecked) => !prevChecked); // Toggle the switch state on label click
  };

  if (loading) {
    return <Loader />; // Render a loading indicator while fetching data
  }
  if (showSelectSubForm) {
    return (
      <AddSubUserForm
        subscriptionFor={finalSubscriptionFor}
        amt={finalAmt}
        plan={finalPlan}
        product={product}
        productCoupon={productCoupon}
      />
    );
  }
  return (
    <>
      <div className="subscribeNowPage">
        <h4>Choose Your Plan</h4>
        <div className="custom-switch">
          <div className="input-switch-container">
            <span
              className={
                checked2 === false
                  ? "switch-label-left"
                  : "switch-label-left checkedfont"
              }
              onClick={handleLabelClick}
            >
              Monthly
            </span>
            <InputSwitch
              offLabel="Monthly"
              onLabel="Annual"
              checked={checked2}
              onChange={(e) => setChecked2(e.value)}
            />
            <span
              className={
                checked2 === false
                  ? "switch-label-right checkedfont"
                  : "switch-label-right"
              }
              onClick={handleLabelClick}
            >
              Annual
            </span>
          </div>
        </div>
        <div className="chooseSubMainContainer">
          <div
            className="SubBtnCard"
            onMouseEnter={() => setMsaHover(true)}
            onMouseLeave={() => setMsaHover(false)}
          >
            <Card
              style={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: msaHover
                  ? "url(/images/payment/monthlyhovercard.png)"
                  : "",
              }}
              className="subcard"
              footer={msafooter}
              header={msaheader}
            ></Card>
          </div>
          <div
            className="SubBtnCard"
            onMouseEnter={() => setRegionHover(true)}
            onMouseLeave={() => setRegionHover(false)}
          >
            <Card
              style={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: regionHover
                  ? "url(/images/payment/monthlyhovercard.png)"
                  : "",
              }}
              className="subcard sub-highlight"
              footer={regionfooter}
              header={regionheader}
            ></Card>
          </div>
          <div
            className="SubBtnCard"
            onMouseEnter={() => setAllHover(true)}
            onMouseLeave={() => setAllHover(false)}
          >
            <Card
              style={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: AllHover
                  ? "url(/images/payment/monthlyhovercard.png)"
                  : "",
              }}
              className="subcard"
              footer={allfooter}
              header={allheader}
            ></Card>
          </div>
          <div
            className="SubBtnCard"
            onMouseEnter={() => setCustomHover(true)}
            onMouseLeave={() => setCustomHover(false)}
          >
            <Card
              style={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: customHover
                  ? "url(/images/payment/monthlyhovercard.png)"
                  : "",
              }}
              className="subcard"
              footer={customfooter}
              header={customheader}
            ></Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionLayout(SubscriptionForm);
